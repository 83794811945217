const employeeList = [
    { id: "0959", name: "Mofiz bhai" },
    { id: "0967", name: "UCP" },
    { id: "0984", name: "Tasu" },
    { id: "7324", name: "Ayon" },
    { id: "8586", name: "Shaks" },
    { id: "8810", name: "Monisha" },
    { id: "8621", name: "Rayeedah" },
    { id: "8632", name: "Zo" },
    { id: "8622", name: "Rashaaaad" },
    { id: "8363", name: "Kashfiii" },
    { id: "8583", name: "Shakib" },
    { id: "8004", name: "Sami" },
    { id: "0957", name: "Mehedi boss" },
    { id: "8651", name: "Mashi" },
    { id: "8678", name: "Tasneeeeem" },
    { id: "8061", name: "Razu" },
    { id: "8391", name: "Rehmi" },
    { id: "8229", name: "Adibebes" },
    { id: "0985", name: "Foara" },
    { id: "8865", name: "Arpita" },
    { id: "8516", name: "Amber" },
    { id: "8585", name: "Savvy" },
];
export default employeeList