import React, { useState, useContext, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import fireApp, { authUsersCollection, myelinersCollectionRef } from "../firebase/fire-app";
import firebase from "firebase/app";
import "firebase/auth"

export const AuthContext = React.createContext();
export const AuthContextProvider = ({ children }) => {
    const authManager = Auth();

    return (
        <AuthContext.Provider value={authManager}>
            {children}
        </AuthContext.Provider>
    );
};



export const useAuth = () => useContext(AuthContext);


const Auth = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loadingUser, setLoadingUser] = useState(false)
    const [hasAccess, setHasAccess] = useState(false);
    const [salesData, setSalesData] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [allAuthUsers, setAllAuthUsers] = useState([])
    const [myelinners, setMyelinners] = useState([])

    const handleGoogleSignIn = () => {
        var provider = new firebase.auth.GoogleAuthProvider();
        return firebase.auth().signInWithPopup(provider).then((result) => {
            const fUser = result.user;
            const userDetails = { id: fUser.uid, name: fUser.displayName, email: fUser.email, photo: fUser.photoURL }
            const loggedEmail = result.user.email;
            authUsersCollection.doc('authUsers').onSnapshot((doc) => {
                const users = doc.data();
                const allUsers = users.admins;
                const isUser = allUsers.filter(item => item === loggedEmail);

                // setCurrentUser(userDetails);
                if (isUser.length > 0) {
                    setHasAccess(true);
                    myelinersCollectionRef.doc(loggedEmail).get()
                        .then((doc) => {
                            if (doc.exists) {
                                const data = doc.data();
                                console.log(data);
                                localStorage.setItem("empID", data.empID)
                                setCurrentUser(data)
                                return data
                            } else {
                                setCurrentUser(null)
                            }
                        })
                } else {
                    setHasAccess(false)
                }

            })

        }).catch(function (error) {
            const errorMessage = error.message;
            alert(errorMessage);
        });
    }

    useEffect(() => {
        fireApp.auth().onAuthStateChanged((result) => {
            if (result) {
                console.log(result);
                const fUser = result;
                const userDetails = { id: fUser.uid, name: fUser.displayName, email: fUser.email, photo: fUser.photoURL }
                const loggedEmail = result.email;
                authUsersCollection.doc('authUsers').onSnapshot((doc) => {
                    const users = doc.data();
                    const admins = users.admins;
                    const isAdmin = admins.filter(item => item === loggedEmail);

                    const allUsers = users.admins;
                    setAllAuthUsers(allUsers)
                    const isUser = allUsers.filter(item => item === loggedEmail);
                    // setCurrentUser(userDetails);
                    if (isAdmin.length > 0) {
                        setIsAdmin(true)
                    } else {
                        setIsAdmin(false)
                    }

                    if (isUser.length > 0) {
                        setHasAccess(true);
                        myelinersCollectionRef.doc(loggedEmail).get()
                            .then((doc) => {
                                if (doc.exists) {
                                    const data = doc.data();
                                    localStorage.setItem("empID", data.empID)
                                    setCurrentUser(data)
                                    return data
                                } else {
                                    setCurrentUser(null)
                                }
                            })
                    } else {
                        setHasAccess(false)
                    }

                })
            }
        });
    }, []);


    const signOut = () => {
        return fireApp
            .auth()
            .signOut()
            .then(() => {

                setCurrentUser(null)
            });
    };
    const basicLog = () => {
        console.log("test log");
    }

    return { allAuthUsers, setAllAuthUsers, showSidebar, setShowSidebar, isAdmin, setIsAdmin, currentUser, setCurrentUser, handleGoogleSignIn, signOut, basicLog, hasAccess, salesData,myelinners, setMyelinners };
};
