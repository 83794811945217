import firebase from "firebase";
import "firebase/auth";
import firebaseConfig from "../firebase-config";
import { webServerLive } from "./liveServerStatus";



let fbConfig;
if (webServerLive) {
    fbConfig = {
        apiKey: "AIzaSyBgq44_gsJxTjjsKzZn8dlBfWm8otU6SnI",
        authDomain: "break-hr-ice.firebaseapp.com",
        projectId: "break-hr-ice",
        storageBucket: "break-hr-ice.firebasestorage.app",
        messagingSenderId: "670211024844",
        appId: "1:670211024844:web:28e1f1d5710febea8b1f69"
    };
} else {
    fbConfig = {
        apiKey: "AIzaSyBgq44_gsJxTjjsKzZn8dlBfWm8otU6SnI",
        authDomain: "break-hr-ice.firebaseapp.com",
        projectId: "break-hr-ice",
        storageBucket: "break-hr-ice.firebasestorage.app",
        messagingSenderId: "670211024844",
        appId: "1:670211024844:web:28e1f1d5710febea8b1f69"
    };
}

const fireApp = firebase.initializeApp(fbConfig);
fireApp
    .firestore()
    .enablePersistence()
    .catch((err) => {
        if (err.code === "failed-precondition") {
            console.log(err);
        } else console.log(err);
    });

export default fireApp;
export const fireStoreDB = fireApp.firestore();
export const fireDatabase = fireApp.database();
export const fireStorage = fireApp.storage();

export const authUsersCollection = fireStoreDB.collection("authUsers");

export const myelinersCollectionRef = fireStoreDB.collection("myeliners");
export const compensationCollectionRef =
    fireStoreDB.collection("compensationInfo");
export const employmentInfoCollectionRef =
    fireStoreDB.collection("employmentInfo");   
export const claimsCollectionRef = fireStoreDB.collection("claims");
export const leavesCollectionRef = fireStoreDB.collection("leaves");
export const payslipsCollectionRef = fireStoreDB.collection("payslips");
export const feedbackCollectionRef = fireStoreDB.collection("feedback");
// export const documentsCollectionRef = fireStoreDB.collection("documents");
export const wfhApplicationCollectionRef = fireStoreDB.collection("wfh");

export const bankAccountCollectionRef = fireStoreDB.collection("bankInfo");
// export const customMailCollectionRef = fireStoreDB.collection("customMail");
export const archivedUsersCollectionRef =
    fireStoreDB.collection("archivedUsers");

// export const dataControlCollectionRef = fireStoreDB.collection("dataControl");


export const webConfigDocRef = fireStoreDB.collection("otherDBs").doc("webGalleryConfig");

export const teamsCollectionRef = fireStoreDB.collection("teams")