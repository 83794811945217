const teams = [
    {
        name: "Dua",
        image: "/teams/dua_lipa.jpeg", // Dua Lipa image
    },
    {
        name: "Swifty",
        image: "/teams/taylor_swift.png", // Taylor Swift image
    }
  
];

export default teams;
