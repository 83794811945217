import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";
import LoginPage from "./LoginPage/LoginPage";

import { useCollectionData } from "react-firebase-hooks/firestore";
import { myelinersCollectionRef } from "../firebase/fire-app";

import PageLoader from "../Components/Shared/PageLoader/PageLoader";
import ViewUserAccess from "./ViewUserAccess/ViewUserAccess";
import AdminPlay from "./AdminPlay/AdminPlay";


const Main = () => {
    const { hasAccess, isAdmin, currentUser, myelinners, setMyelinners } =
        useAuth();
    const [empEmail, setEmpEmail] = useState(null);


    const [systemLoading, setSystemLoading] = useState(false);

    const [getMyelinners, loading, error] = useCollectionData(
        myelinersCollectionRef.orderBy("empID", "desc"),
        { idField: "id" }
    );

    useEffect(() => {
        if (getMyelinners && getMyelinners.length > 0) {
            setMyelinners(getMyelinners);
        }
    }, [getMyelinners]);

    const giveAccess = hasAccess;
    useEffect(() => {
        if (currentUser && currentUser.empID && currentUser.email) {
            setEmpEmail(currentUser.email);
            setSystemLoading(false);
        }
    }, [currentUser]);
    return (
        <>
            <Router>
                {systemLoading ? (
                    <PageLoader />
                ) : (
                    <Routes>
                        

                        {giveAccess && (
                            <Route
                                path="/control"
                                element={
                                    <AdminPlay email={empEmail} />
                                }
                            />
                        )}
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="*" element={<ViewUserAccess />} />
                    </Routes>
                )}
            </Router>
        </>
    );
};

export default Main;
