import { useNavigate, useLocation, Link } from "react-router-dom";
import "firebase/auth";
import GoogleIcon from "./google.png";
import "./LoginPage.scss";
import { useAuth } from "../../Hooks/useAuth";
import fireApp, { userInfoCollectionRef } from "../../firebase/fire-app";
import { useForm } from "react-hook-form";
import PasswordResetPopup from "./PasswordResetPopup";
import { webServerLive } from "../../firebase/liveServerStatus";
import MyelinerRegistrationPopup from "./MyelinerRegistrationPopup";

const LoginPage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const {
        currentUser,
        setCurrentUser,
        hasAccess,
        handleGoogleSignIn,
        signOut,
        basicLog,
    } = useAuth();

    const signInHandler = () => {
        return handleGoogleSignIn().then((data) => {
            navigate(from);
        });
    };

    const onFormSubmit = (data, e) => {
        // alert(JSON.stringify(data))
        const email = data.email;
        const password = data.password;
        fireApp
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                const user = userCredential.user;
                setCurrentUser(user);
                // setRegistered(true);
                navigate(from);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorMessage);
            });
    };

    return (
        <>
            <section
                className={webServerLive ? "bg-primary py-3" : "bg-info py-3"}
            >
                <div className="container text-white text-white mb-0">
                    <h3>Break the ICE</h3>
                </div>
            </section>
            <section>
                <div className="container mt-4">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-7">
                            <div className="py-4 pb-5 px-5 login-layout">
                                <h2 className="text-center">
                                    {currentUser && currentUser.name}
                                </h2>

                                <div className="mt-4">
                                    <form onSubmit={handleSubmit(onFormSubmit)}>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="email"
                                                className="col-sm-3 col-form-label"
                                            >
                                                Email
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    name="email"
                                                    type="email"
                                                    {...register("email", {
                                                        required: true,
                                                    })}
                                                    className="form-control"
                                                    id="email"
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="password"
                                                className="col-sm-3 col-form-label"
                                            >
                                                Password
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    name="password"
                                                    type="password"
                                                    {...register("password", {
                                                        required: false,
                                                    })}
                                                    className="form-control"
                                                    id="password"
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-3"></div>
                                            <div className="col-sm-9 ">
                                                <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        id="flexCheckChecked"
                                                        className="form-check-input"
                                                    />
                                                    <label
                                                        htmlFor="flexCheckChecked"
                                                        className="form-check-label"
                                                    >
                                                        Keep me singed in!
                                                    </label>
                                                </div>
                                                <div className=" mt-4 align-er">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary mb-4 me-3"
                                                    >
                                                        Login
                                                    </button>{" "}
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <span className="font-18">
                                        Forgot password? <PasswordResetPopup />{" "}
                                    </span>
                                    <br /> <br />
                                    <MyelinerRegistrationPopup />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LoginPage;
