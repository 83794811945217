import React, { useState, useRef } from "react";

import "./style.css";

const AdminPlay = () => {
    const tracks = [
        { id: 1, name: "Track 1", url: "/music/1.mp3" },
        { id: 2, name: "Track 2", url: "/music/2.mp3" },
        { id: 3, name: "Track 3", url: "/music/3.mp3" },
        { id: 4, name: "Track 4", url: "/music/4.mp3" },
        { id: 5, name: "Track 5", url: "/music/5.mp3" },
        { id: 6, name: "Track 6", url: "/music/6.mp3" },
        { id: 7, name: "Track 7", url: "/music/7.mp3" },
        { id: 8, name: "Track 8", url: "/music/8.mp3" },
        { id: 9, name: "Track 9", url: "/music/9.mp3" },
        { id: 10, name: "Track 10", url: "/music/10.mp3" },
        { id: 11, name: "Track 10", url: "/music/11.mp3" },
    ];
    const [currentTrack, setCurrentTrack] = useState(null);
    const [currentTrackId, setCurrentTrackId] = useState(null);
    const audioRef = useRef(new Audio());

    const playTrack = (track) => {
        if (currentTrackId !== track.id) {
            audioRef.current.pause(); // Pause the currently playing track
            audioRef.current.src = track.url; // Set the new track
            audioRef.current.play();
            setCurrentTrackId(track.id); // Update the current track
        } else {
            audioRef.current.play();
        }
    };

    const pauseTrack = () => {
        audioRef.current.pause();
        setCurrentTrackId(null); // Reset the current track ID
    };

    const toggleTrack = (track) => {
        if (currentTrackId === track.id) {
            pauseTrack();
        } else {
            playTrack(track);
        }
    };
    return (
        <>
            <div className="music-container">
                <h1>Music Player</h1>
                <div className="music-list">
                    {tracks.map((track) => (
                        <div key={track.id} className="music-card">
                            <span>{track.name}</span>
                            <button
                                onClick={() => toggleTrack(track)}
                                className={
                                    currentTrackId === track.id ? "playing" : ""
                                }
                            >
                                {currentTrackId === track.id ? "Pause" : "Play"}
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default AdminPlay;
