import React, { useEffect, useState } from "react";
import {
    getFirestore,
    doc,
    getDoc,
    setDoc,
    updateDoc,
} from "firebase/firestore";
import { teamsCollectionRef } from "../../firebase/fire-app";
import "./style.css";
import employeeList from "./emp-data";
import teams from "./teams-data";
import {
    useCollectionData,
    useDocumentData,
} from "react-firebase-hooks/firestore";

const ViewUserAccess = () => {
    const [teamData, setTeamData] = useState({ Swifty: [], Dua: [] });
    const [getDua, loadDua, errorDua] = useDocumentData(
        teamsCollectionRef.doc("Dua")
    );
    const [getSwifty, loadSwifty, errorSwifty] = useDocumentData(
        teamsCollectionRef.doc("Swifty")
    );

    // Fetch team data from Firestore

    const [selectedTeam, setSelectedTeam] = useState("");
    const [employeeId, setEmployeeId] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const handleRegister = async () => {
        if (!selectedTeam || !employeeId || employeeId.length !== 4) {
            setErrorMessage(
                "Please select a team and enter a valid 4-digit Employee ID."
            );
            return;
        }

        // Validate the employee ID
        const employee = employeeList.find((emp) => emp.id === employeeId);
        if (!employee) {
            setErrorMessage(
                "Invalid Employee ID. Please check the list and try again."
            );
            return;
        }

        try {
            // Fetch both teams
            const teamSwiftyDoc = await teamsCollectionRef.doc("Swifty").get();
            const teamDuaDoc = await teamsCollectionRef.doc("Dua").get();

            const teamSwifty = teamSwiftyDoc.exists
                ? teamSwiftyDoc.data().members || []
                : [];
            const teamDua = teamDuaDoc.exists
                ? teamDuaDoc.data().members || []
                : [];

            // Check if the Employee ID is already registered in any team
            const isInSwifty = teamSwifty.some(
                (member) => member.id === employeeId
            );
            const isInDua = teamDua.some((member) => member.id === employeeId);

            if (isInSwifty || isInDua) {
                const registeredTeam = isInSwifty ? "Swifty" : "Dua";
                setErrorMessage(
                    `This Employee ID is already registered in team ${registeredTeam}.`
                );
                return;
            }

            // Check if the selected team is full
            if (selectedTeam === "Swifty" && teamSwifty.length >= 11) {
                setErrorMessage(
                    "Team Swifty is already full. Please try registering for Team Dua."
                );
                return;
            }

            if (selectedTeam === "Dua" && teamDua.length >= 11) {
                setErrorMessage(
                    "Team Dua is already full. Please try registering for Team Swifty."
                );
                return;
            }

            // Add the Employee to the selected team
            const teamRef = teamsCollectionRef.doc(selectedTeam);
            const selectedTeamMembers =
                selectedTeam === "Swifty" ? teamSwifty : teamDua;

            selectedTeamMembers.push({ id: employee.id, name: employee.name });
            await teamRef.update({ members: selectedTeamMembers });

            setSuccessMessage(
                `Successfully registered ${employee.name} in team ${selectedTeam}!`
            );
            setErrorMessage("");
            setEmployeeId("");
        } catch (error) {
            setErrorMessage(
                "An error occurred while registering. Please try again."
            );
            console.error(error);
        }
    };
    return (
        <>
            <div className="team-container">
                <h1>Team Registration</h1>
                <div className="team-selection">
                    {teams.map((team) => (
                        <div
                            key={team.name}
                            className={`team-card ${
                                selectedTeam === team.name ? "selected" : ""
                            }`}
                            onClick={() => setSelectedTeam(team.name)}
                        >
                            <img
                                src={team.image}
                                alt={team.name}
                                className="team-image"
                            />
                            <p>{team.name}</p>
                        </div>
                    ))}
                </div>

                <div>
                    <label>Enter Your Employee ID:</label>
                    <input
                        type="text"
                        value={employeeId}
                        onChange={(e) => setEmployeeId(e.target.value)}
                        placeholder="4-digit Employee ID"
                        maxLength={4}
                        className="employee-input"
                    />
                </div>

                <button onClick={handleRegister} className="register-button">
                    Enter
                </button>

                {errorMessage && (
                    <p className="error-message">{errorMessage}</p>
                )}
                {successMessage && (
                    <p className="success-message">{successMessage}</p>
                )}

                <div className="team-list">
                    <div className="team-column">
                        <h2>Team Dua</h2>
                        <ul>
                        {!loadDua &&
        getDua?.members?.length > 0 &&
        getDua.members.map((member) => (
          <li key={member.id}>{member.name}</li>
        ))}
                        </ul>
                    </div>
                    <div className="team-column">
                        <h2>Team Swifty</h2>
                        <ul>
                        {!loadSwifty &&
        getSwifty?.members?.length > 0 &&
        getSwifty.members.map((member) => (
          <li key={member.id}>{member.name}</li>
        ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewUserAccess;
